:root {
  --black: #121212;
  --blackt: #12121280;
  --gray: #2e2e2e;
  --grayt: #838b9b80;
  --color: #0b0b0b;
  --color2: #c8b6ff;
  --white: #E2E2E2;
  --transp: transparent;
}

* {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--white);

  &h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    margin: 0;
  }

  & h1 {
    font-size: 5rem;
    font-weight: 900;
  }

  & h2 {
    font-size: 3rem;
  }

  & h3 {
    font-size: 2rem;
  }

  & h4 {
    font-size: 1rem;
  }

  & h5 {
    font-size: 1rem;
  }

  & p {
    font-size: 1rem;
    margin: 0;
  }

  & a {
    text-decoration: none;
    color: var(--white);
  }

  & a:hover,
  & a:active {
    text-decoration: underline;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hero_wrapper {
  width: 100%;
  overflow: hidden;
  background: var(--color);

  & canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    pointer-events: all;
  }

  & .hero_content {
    min-height: 100vh;
    width: 100%;
    color: var(--gray);
    text-align: center;
    display: grid;
    place-content: center;
    padding: 10vh 5vw;
    position: relative;
    z-index: 1;

    & h2 {
      position: relative;
      line-height: 1;
      margin: 0;
      z-index: 2;
      text-align: center;
      font-size: 10vw;
      text-transform: uppercase;
      font-weight: 900;
      pointer-events: none;
      letter-spacing: -0.5vw;
      background-image: repeating-linear-gradient(95deg,
      var(--transp) 0,
      var(--transp) 0.4vw,
      var(--gray) 0.4vw,
      var(--gray) 1vw);
      background-clip: text;
      -webkit-text-fill-color: transparent;

      &:nth-child(2n) {
        background-image: repeating-linear-gradient(90deg,
        var(--transp) 0,
        var(--transp) 0.4vw,
        var(--gray) 0.4vw,
        var(--gray) 1vw);
      }
      &:nth-child(3n) {
        background-image: repeating-linear-gradient(85deg,
        var(--transp) 0,
        var(--transp) 0.4vw,
        var(--gray) 0.4vw,
        var(--gray) 1vw);
      }

    }

    & h5 {
      color: var(--gray);
      position: relative;
      z-index: 2;
      margin: 10vh auto 0;
      text-transform: uppercase;
      letter-spacing: 5px;
      cursor: default;
      line-height: 1;
    }

    & p {
      color: var(--gray);
      position: relative;
      display: block;
      font-size: 12px;
      cursor: default;
      font-weight: 600;
      margin: 20px auto;
      letter-spacing: 0px;
      line-height: 1;
      z-index: 2;

    }

    & a {
      position: relative;
      z-index: 2;
      display: inline-block;
      color: var(--gray);
      backdrop-filter: blur(10px);
    }
  }
}

.portfolio_wrap {
  position: relative;
  margin: 0 auto;
  padding: 100px 10%;
  background: var(--black);

  & h2 {
    font-size: 10px;
    text-align: center;
  }
}